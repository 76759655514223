<template>
  <div id="tender-list">
    <v-card>
      <StandardCardHeader
        :go-to-link="'admin-stock-management-add'"
        :title="'Stock management'"
        :button-text="'Create'"
        :show-action-button="false"
        :link-button-color="'success'"
      />
      <v-row class="px-2 ma-0">
        <v-col
          cols="12"
          sm="3"
        >
          <v-text-field
            v-model="searchQuery"
            :placeholder="$t('Type to search')"
            clearable
            outlined
            hide-details
            dense
            class="tender-search mb-4"
            @keyup="getDataFromApi"
            @click:clear.prevent="resetSearch()"
          >
          </v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="3"
          lg="3"
        >
          <v-btn
            color="success"
            outlined
            @click="() => showCategoryDialog = true"
          >
            <v-icon>{{ icons.mdiFilterOutline }}</v-icon>
            {{ $t('Category') }}
            <span v-if="getSelectedCategory">
              &nbsp;{{ `(${getSelectedCategory})` }}
            </span>
          </v-btn>
        </v-col>
      </v-row>

      <v-data-table
        v-model="selectedRows"
        dense
        :headers="tableColumns"
        :items="stockListTable"
        :items-per-page="getItemsPerPageFromLS()"
        :options.sync="options"
        :server-items-length="totalDiaries"
        :loading="loading"
        :no-results-text="$t('noResults')"
        :no-data-text="$t('noResults')"
        :footer-props="{
          'items-per-page-text': '#',
          'items-per-page-options': [10, 20, 50, 100],
          'page-text': `{0}-{1} ${$t('of')} {2}`
        }"
      >
        <template #[`item.name`]="{item}">
          <div class="d-flex align-center">
            <div class="d-flex flex-column ms-3">
              <router-link
                :to="{
                  name : 'admin-stock-management-add',
                  params: {
                    code: item.code,
                    item_id: item.id,
                    unit: item.unit,
                    price: item.price,
                    stock_item_category_id: item.stock_item_category_id,
                    item
                  }
                }"
                class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{ item.name }}
              </router-link>
            </div>
          </div>
        </template>

        <template #[`item.quantity`]="{item}">
          <div class="d-flex align-center">
            <div class="d-flex flex-column ms-3">
              <router-link
                :to="{
                  name : 'admin-stock-management-add',
                  params: {
                    code: item.code,
                    item_id: item.id,
                    unit: item.unit,
                    price: item.price,
                    stock_item_category_id: item.stock_item_category_id,
                    item
                  }
                }"
                class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{ getQuantity(item) }}
              </router-link>
            </div>
          </div>
        </template>

        <template #[`item.price`]="{item}">
          <div class="d-flex align-center ">
            <div class="d-flex flex-column ms-3">
              <router-link
                :to="{
                  name : 'admin-stock-management-add',
                  params: {
                    code: item.code,
                    item_id: item.id,
                    unit: item.unit,
                    price: item.price,
                    stock_item_category_id: item.stock_item_category_id,
                    item
                  }
                }"
                class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{ item.price ? (item.price).toLocaleString('en-GB', {minimumFractionDigits: 2, maximumFractionDigits: 2}) : item.price }}
              </router-link>
            </div>
          </div>
        </template>

        <template #[`item.unit`]="{item}">
          <div class="d-flex align-center">
            <div class="d-flex flex-column ms-3">
              <router-link
                :to="{
                  name : 'admin-stock-management-add',
                  params: {
                    code: item.code,
                    item_id: item.id,
                    unit: item.unit,
                    price: item.price,
                    stock_item_category_id: item.stock_item_category_id,
                    item
                  }
                }"
                class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{ item.unit }}
              </router-link>
            </div>
          </div>
        </template>

        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                :to="{
                  name: 'admin-stock-management-add',
                  params: {
                    code: item.code,
                    item_id: item.id,
                    unit: item.unit,
                    price: item.price,
                    stock_item_category_id: item.stock_item_category_id,
                    item
                  }
                }"
                link
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiFileDocumentEditOutline }}
                  </v-icon>
                  <span>{{ $t('Edit') }}</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="!item.deleted_at"
                link
              >
                <v-list-item-title @click.prevent="deleteStockItem(item.id)">
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>{{ $t('Delete') }}</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="item.deleted_at"
                link
              >
                <v-list-item-title @click.prevent="restoreStockItem(item.id)">
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiFileRestoreOutline }}
                  </v-icon>
                  <span>{{ $t('Restore') }}</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>

      <v-dialog
        v-model="showCategoryDialog"
        max-width="600px"
      >
        <v-card>
          <v-card-title>
            <span
              class="headline"
            >Odaberite kategoriju</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row class="w-full">
                <v-col
                  cols="12"
                >
                  <v-list>
                    <v-list-item
                      v-for="stockItemCategory in stockItemCategories"
                      :key="stockItemCategory.id"
                      @click.prevent="selectCategory(stockItemCategory.id)"
                    >
                      {{ stockItemCategory.name }}
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              outlined
              @click="() => showCategoryDialog = false"
            >
              {{ $t('Close') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiAccountOutline,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiExportVariant,
  mdiFileDocumentEditOutline,
  mdiFileDocumentOutline,
  mdiFileRestoreOutline,
  mdiPlus,
  mdiSquareEditOutline,
  mdiFilterOutline,
} from '@mdi/js'

// sidebar
import axiosIns from '@axios'
import StandardCardHeader from '@/components/shared/StandardCardHeader.vue'
import searchMixin from '@/mixins/searchMixin'
import { getItemsPerPageFromLS } from '@/services/localStorageService'

export default {
  components: { StandardCardHeader },
  mixins: [searchMixin],
  data() {
    const selectedRows = []
    const stockListTable = []
    const totalDiaries = 0
    const loading = true
    const options = {}
    const selectedCategory = this.$store.getters.getSelectedStockItemCategory
    const stockItemCategories = []
    const showCategoryDialog = !this.$store.getters.getSelectedStockItemCategory

    const tableColumns = [
      {
        text: this.$t('Item'),
        value: 'name',
      },
      {
        text: this.$t('Code'),
        value: 'code',
      },
      {
        text: this.$t('Quantity'),
        value: 'quantity',
      },
      {
        text: this.$t('Price'),
        value: 'price',
      },
      {
        text: this.$t('Unit'),
        value: 'unit',
      },
      {
        text: this.$t('Stock'),
        value: 'stock.name',
      },
      {
        text: this.$t('Actions'),
        value: 'actions',
        align: 'center',
        sortable: true,
      },
    ]

    return {
      stockItemCategories,
      selectedCategory,
      showCategoryDialog,
      options,
      loading,
      statusFilter: '',
      tableColumns,
      stockListTable,
      selectedRows,
      totalDiaries,
      icons: {
        mdiFileDocumentEditOutline,
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiExportVariant,
        mdiAccountOutline,
        mdiFileRestoreOutline,
        mdiFilterOutline,
      },
    }
  },
  computed: {
    getSelectedCategory() {
      if (this.$store.getters.getStockItemCategories.length === 0 || !this.selectedCategory) {
        return false
      }

      return this.$store.getters.getStockItemCategories.find(c => c.id === this.selectedCategory).name
    },
  },

  watch: {
    options: {
      handler() {
        this.getDataFromApi()
      },
      deep: true,
    },
  },
  async mounted() {
    if (this.$store.getters.getStockItemCategories.length === 0) {
      await this.$store.dispatch('fetchStockItemCategories')
    }
    this.stockItemCategories = this.$store.getters.getStockItemCategories
  },
  methods: {
    getItemsPerPageFromLS,
    getQuantity(item) {
      const stockItem = item.stock_item_items.find(el => +el.stock_id === +this.$route.params.id)
      if (!stockItem) {
        return null
      }

      return item.stock_item_items.length > 0
        ? (+stockItem?.quantity).toLocaleString('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
        : '0.00'
    },
    selectCategory(id) {
      this.selectedCategory = id
      this.getDataFromApi()
      this.$store.commit('SET_STOCK_ITEM_CATEGORY', id)
      this.showCategoryDialog = false
    },
    getDataFromApi() {
      localStorage.setItem('itemsPerPage', `${this.options.itemsPerPage}`)
      this.loading = true
      const orderBy = this.options.sortBy.length > 0 ? this.options.sortBy[0] : 'id'
      const orderDirection = this.options.sortDesc.length > 0 ? this.options.sortDesc[0] : 'true'
      const search = this.searchQuery ?? ''
      const status = this.statusFilter ?? ''
      const categoryId = this.selectedCategory ?? ''
      axiosIns.get(`/admin/stocks/${this.$route.params.id}/items?page=${this.options.page}&perPage=
      ${this.options.itemsPerPage}&orderBy=${orderBy}&orderDirection=${orderDirection}&search=${search}&status=${status}&category=${categoryId}`)
        .then(res => {
          this.totalDiaries = res.data.items.total
          this.stockListTable = res.data.items.data
          this.loading = false
        })
        .catch()
    },
    deleteStockItem(id) {
      axiosIns.delete(`/admin/stocks/${this.$route.params.id}/items/${id}`)
        .then(() => {
          this.getDataFromApi()
        })
        .catch()
    },
    restoreStockItem(id) {
      axiosIns.put(`/admin/stocks/${this.$route.params.id}/items/${id}/restore`)
        .then(() => {
          this.getDataFromApi()
        })
        .catch()
    },
  },
}
</script>
